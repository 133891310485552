import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 100px;
`
const Row = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
const Row2 = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column-reverse;
  gap: 1rem;

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const TextDiv = styled.div`
  width: 100%;

  h2 {
    font-size: 1rem !important;
  }
`

const CanyonLogo = styled.div`
  width: 50%;
  margin: auto;
`

const ImageDivCrookedRight = styled.div`
  width: 100%;
  transform: rotate(1deg);
  border: 4px solid white;
  box-shadow: 0 0 1px black;
`

const ImageDivCrookedLeft = styled.div`
  width: 100%;
  z-index: 2;
  transform: rotate(-1deg);
  box-shadow: 10px -5px 5px rgba(0, 0, 0, 0.5);
  border: 4px solid white;

  @media only screen and (min-width: 980px) {
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.5);
  }
`

const ImageDivOnTopLeft = styled.div`
  width: 100%;
  transform: translateY(5%) translateX(-10%) rotate(-1deg);
  z-index: 2;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  border: 4px solid white;

  @media only screen and (min-width: 980px) {
    transform: translateX(15%) rotate(-1deg);
  }
`

const ImageDivOnTopRightPort = styled.div`
  width: 70%;
  transform: translateY(10%) translateX(10%) rotate(1deg);
  border: 4px solid white;
  box-shadow: 0 0 1px black;

  @media only screen and (min-width: 980px) {
    transform: translateX(-15%) rotate(1deg);
  }
`

const Verkstad = () => (
  <Layout
    seo="Cykelverkstad &amp; Dämparservice i Åre"
    meta="Vi kan som enda verkstad i Åre erbjuda fullständig dämparservice på
  Fox, RockShox, EXT, Öhlins, DVO m.fl. I vår
  fullutrustade cykelverkstad kan vi hjälpa dig med det mesta som du
  kan tänkas behöva hjälp med."
  >
    <Container>
      <Row>
        <TextDiv>
          <h1>Cykelverkstad &amp; dämparservice i Åre</h1>
          <h2>Vi har byns enda fullserviceverkstad.</h2>
          <p>
            Vi kan som enda verkstad i Åre erbjuda fullständig dämparservice på
            Fox, RockShox, EXT, Öhlins, DVO m.fl. I vår fullutrustade
            cykelverkstad kan vi hjälpa dig med det mesta som du kan tänkas
            behöva hjälp med.
          </p>
        </TextDiv>
        <ImageDivOnTopLeft>
          <StaticImage
            src="../images/page-images/bike-republic-hjulriktare.jpg"
            alt="Verkstad - Bike Republic"
          />
        </ImageDivOnTopLeft>
        <ImageDivCrookedRight>
          <StaticImage
            src="../images/page-images/bike-republic-verkstad.jpg"
            alt="Verkstad - Bike Republic"
          />
        </ImageDivCrookedRight>
      </Row>
      <Row2>
        <ImageDivCrookedLeft>
          <StaticImage
            src="../images/page-images/bike-republic-suspension-service.jpg"
            alt="Verkstad - Bike Republic"
          />
        </ImageDivCrookedLeft>
        <ImageDivOnTopRightPort>
          <StaticImage
            src="../images/page-images/bike-republic-service.jpg"
            alt="Verkstad - Bike Republic"
          />
        </ImageDivOnTopRightPort>
        <TextDiv>
          <p>
            Vi jobbar med alla komponentmärken och med alla cyklar. Vi är ett
            Canyon Authorized Service Center, vilket betyder att vi är utbildade
            på Canyon och kan hantera garanti och reklamations ärenden samt har
            Canyon originaldelar för att se till att din Canyoncykel är i
            toppskick.
          </p>
          <CanyonLogo>
            <StaticImage
              src="../images/canyon-authorized-servicepartner-white.png"
              alt="Canyon Authorized Servicepartner - Bike Republic"
            />
          </CanyonLogo>
        </TextDiv>
      </Row2>
    </Container>
  </Layout>
)

export default Verkstad
